import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "store/rtkquery/base";

export const livesessions = createApi({
  reducerPath: "livesession",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getSession: builder.query({
      query: (params) => ({
        url: "/live/session/care-provider/get-session",
        params,
      }),
    }),
    postSessionActivity: builder.mutation({
      query: (data) => ({
        url: "/live/session/user/activity",
        method: "POST",
        data,
      }),
    }),
    endSession: builder.mutation({
      query: (data) => ({
        url: "/live/session/care-provider/end-session",
        method: "POST",
        data,
      }),
    }),
    fetchCoachSlots: builder.query({
      query: (params) => ({
        url: "/availability/recurring/provider-slots",
        params,
      }),
    }),
    getPatientHealthInfo: builder.query({
      query: ({ patientId }) => ({
        url: `/patient/${patientId}/health-record`,
      }),
    }),
    getPatientHealthCollectionInfo: builder.query({
      query: (params) => ({
        url: `/ops/patient/${params?.patientId}/health-record-collection/get`,
        params,
      }),
    }),
    sendAppDownloadLink: builder.query({
      query: (patientId) => ({
        url: `/patient/${patientId}/sendAppDownloadLink`,
      }),
    }),
    updateRecordingStatus: builder.mutation({
      query: (data) => ({
        url: `/patient/${data.patientId}/recording-consent`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["patientRecordingStatus"],
    }),
    getPatientRecordingStatus: builder.query({
      query: ({ params, patientId }) => ({
        url: `/patient/${patientId}/patient-record`,
        params,
      }),
      providesTags: ["patientRecordingStatus"],
    }),
    getPatientPendingForms: builder.query({
      query: ({ params, patientId }) => ({
        url: `/patient/${patientId}/schedule/getForms`,
        params,
      }),
    }),
    sendCoachQuery: builder.mutation({
      query: (data) => ({
        url: `/patient/${data.patientId}/raise-query`,
        method: "POST",
        data,
      }),
    }),
    getSessionCertificates: builder.query({
      query: ({ params, sessionId }) => ({
        url: `/live/session/${sessionId}/patient-eligible-certificate`,
        params,
      }),
    }),
    submitPatientCertificate: builder.mutation({
      query: (data) => ({
        url: `/live/session/${data?.sessionId}/save-patient-certificate`,
        method: "POST",
        data,
      }),
    }),
    getPatientFoundationalCommitment: builder.query({
      query: ({ patientId, params }) => ({
        url: `/patient/${patientId}/foundation-rules`,
        params,
      }),
    }),
    getPatientPreviousSessionTaskInfo: builder.query({
      query: ({ patientId, params }) => ({
        url: `/patient/${patientId}/tasks/for-coach-live-session`,
        params,
      }),
    }),
    getExerciseSwapRecommendation: builder.query({
      query: ({ courseSessionId, params }) => ({
        url: `/curriculum/course-session/${courseSessionId}/exercise/recommendation-for-change`,
        params,
      }),
    }),
    updateExerciseContent: builder.mutation({
      query: (data) => ({
        url: `/schedule/${data?.scheduleId}/change-session-content`,
        method: "POST",
        data,
      }),
    }),
    switchToAgora: builder.mutation({
      query: (data) => ({
        url: `/live/session/${data?.sessionId}/change-vc-provider`,
        method: "POST",
        data: {
          vcProvider: "agora",
        },
      }),
    }),
    getAICRData: builder.query({
      query: ({ patientId }) => ({
        url: `/ops/patient/${patientId}/health-record-collection/get?name=AICR_QUESTION_FORM`,
      }),
    }),
    getPatientDetails: builder.query({
      query: ({ patientId }) => ({
        url: `/patient/${patientId}/details`,
      }),
    }),
  }),
});

export const {
  useLazyGetSessionQuery,
  usePostSessionActivityMutation,
  useEndSessionMutation,
  useLazyFetchCoachSlotsQuery,
  useLazyGetPatientHealthInfoQuery,
  useLazyGetPatientHealthCollectionInfoQuery,
  useLazySendAppDownloadLinkQuery,
  useUpdateRecordingStatusMutation,
  useGetPatientRecordingStatusQuery,
  useLazyGetPatientPendingFormsQuery,
  useSendCoachQueryMutation,
  useLazyGetSessionCertificatesQuery,
  useSubmitPatientCertificateMutation,
  useLazyGetPatientFoundationalCommitmentQuery,
  useLazyGetPatientPreviousSessionTaskInfoQuery,
  useLazyGetExerciseSwapRecommendationQuery,
  useUpdateExerciseContentMutation,
  useSwitchToAgoraMutation,
  useLazyGetAICRDataQuery,
  useLazyGetPatientDetailsQuery,
} = livesessions;

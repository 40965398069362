import { Outlet } from "react-router-dom";
// import { getAuthCookie } from "helpers/utils";
// import { ROUTES } from "./routes";

export default function PrivateLayout() {
  // const token = getAuthCookie();

  // if (!token) {
  //   return <Navigate to={ROUTES.LOGIN} />;
  // }
  return <Outlet />;
}

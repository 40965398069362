import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "store/rtkquery/base";

// Define a service using a base URL and expected endpoints
export const loginWithPassword = createApi({
  reducerPath: "loginWithPassword",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    postLogin: builder.query({
      query: (body) => ({
        url: "/care-provider/login",
        method: "POST",
        data: body,
      }),
    }),
  }),
});

export const userInfo = createApi({
  reducerPath: "userInfo",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getUserInfo: builder.query({
      query: () => ({
        url: "/care-provider/me",
      }),
    }),
    getFirebaseToken: builder.mutation({
      query: () => ({
        url: "/user/firebaseAuth",
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/user/log-out",
        method: "POST",
      }),
    }),
    refreshLogin: builder.mutation({
      query: (token) => ({
        url: "/user/refresh-login",
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});

export const { useLazyPostLoginQuery } = loginWithPassword;
export const {
  useGetUserInfoQuery,
  useGetFirebaseTokenMutation,
  useLogoutMutation,
  useRefreshLoginMutation,
} = userInfo;
